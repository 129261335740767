<template>
  <p class="title font-weight-light" style="line-height: 1.7 !important">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'BaseText'
}
</script>
