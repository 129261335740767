<template>
  <section id="services" class="overflow-hidden">
    <v-row class="accent2" no-gutters>
      <v-col class="text-center pa-5" cols="12" md="6">
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12">
            <BaseBubble2 style="transform: translateX(55%)" />
            <base-heading class="info--text"> NUESTRA SOLUCIÓN </base-heading>
            <v-row align="center" justify="center">
              <v-col cols="8">
                <base-text class="mb-5">
                  Ofrecemos un producto integral que reconoce necesidades de
                  médicos, pacientes y otros actores implicados en el
                  mantenimiento de la salud respiratoria en EPOC.
                </base-text>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-for="(service, i) in services"
            :key="i"
            class="text-center mb-3"
            md="6"
          >
            <v-avatar class="elevation-6 mb-2" color="#69A1BB" size="100">
              <v-icon dark size="80" v-text="service.icon" />
            </v-avatar>

            <base-text>
              <div class="mb-2 headline" v-text="service.name" />
              <div v-html="service.blurb" />
            </base-text>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/services.jpg')" height="100vh" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BaseText from '@/components/base/Text'
import BaseBubble2 from '@/components/base/Bubble2'
import BaseHeading from '@/components/base/Heading'

export default {
  name: 'Services',
  components: {
    BaseHeading,
    BaseText,
    BaseBubble2
  },
  data: () => ({
    services: [
      {
        name: 'Seguimiento Médico',
        icon: 'mdi-doctor',
        blurb:
          'Historial clinico y estádisticas de la  evolución <br> de la enfermedad del paciente.'
      },
      {
        name: 'Sistema experto',
        icon: 'mdi-brain',
        blurb:
          'Recomendaciones para el paciente en <br> función de la evolución de su EPOC.'
      },
      {
        name: 'Chat Bot',
        icon: 'mdi-robot-love',
        blurb:
          'Comunicación amigable a traves de Whatsapp y Telegram para hacerlo accesible para todos.'
      },
      {
        name: 'Aplicación Multiplataforma',
        icon: 'mdi-desktop-classic',
        blurb:
          'Alternativa web mas visual y rapida que el chat bot disponible en diferentes plataformas web y movil.'
      }
    ]
  })
}
</script>
